<template>
  <b-card 
    v-if="products && box"
    :title="'Productos de la caja #' + box.box_number"  >

    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Buscar</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Buscar"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="products"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'serial'"
          class="text-nowrap"
        >
          <span class="text-nowrap" :class="props.row.product.newness ? 'text-danger' : ''">{{ props.row.product.serial }}</span><br>
        </span>

        <span
          v-else-if="props.column.field === 'productType'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ props.row.product.product_type.name }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'numberOrder'"
          class="text-nowrap">
          <span class="text-nowrap">{{ props.row.product.number_order }}</span>
        </span>

        <span
          v-else-if="props.column.field === 'created_at'"
        >
          {{ formatDateVariant(props.row.created_at) }}
        </span>

        <span
          v-else-if="props.column.field === 'update_at'"
        >
          {{ formatDateVariant(props.row.update_at) }}
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Mostrando 1 a
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['20','40','60']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> de {{ props.total }} items </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import axios from 'axios'
import {constants} from "@/helpers/constants"
import {formatDate} from "@/helpers/utils"

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken'),
      products: [],
      order: null,
      pageLength: 20,
      dir: false,
      box: null,
      columns: [
        {
          label: 'ID',
          field: 'id',
        },
        {
          label: 'Serial',
          field: 'serial',
        },
        {
          label: 'Tipo de producto',
          field: 'productType',
        },
        {
          label: 'Numero de transporte',
          field: 'numberOrder',
        },
        {
          label: 'Novedad',
          field: 'product.newness.newness_type.name',
        },
        {
          label: 'Creado',
          field: 'created_at',
        },
        {
          label: 'Actualizado',
          field: 'update_at',
        },
      ],
      searchTerm: '',
      status: [{
        1: 'En alistamiento',
        2: 'Despachado',
        3: 'Cancelado',
        4: 'Finalizado',
        5: 'Recibido',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  props: {
    boxId: {
      type: String,
      required: true,
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    formatDateVariant() {
      return date => formatDate(date)
    },
  },
  created() {

    this.getBoxProducts()

  },
  methods: {
    getBoxProducts() {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.get(this.host + '/api/front-web/get-box-products/' + this.boxId, { headers }).then(response => {
        console.log('get-box-products: ', response)

        this.products = response.data.data.products
        this.box = response.data.data.box
        
      }).catch( err => {
        console.log(err)
      })
    }
  },
}
</script>
