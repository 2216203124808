<template>
  <b-row>
    <b-col cols="12">
      <products-box-table
          :box-id="boxId" />
    </b-col>
  </b-row>
</template>

<script>
import router from '@/router'
import { BRow, BCol } from 'bootstrap-vue'
import ProductsBoxTable from './ProductsBoxTable.vue'

export default {
  components: {
    BRow,
    BCol,

    ProductsBoxTable
  },
  data() {
    return {
      boxId: null,
    }
  },
  created() {
    this.boxId = router.currentRoute.params.box_id
    console.log(this.boxId)
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>